import React from 'react';

const TBD = () => (
  <abbr
    className="border-b border-zinc-200"
    style={{ textDecoration: 'none' }}
    title="To be determined"
  >
    TBD...
  </abbr>
);

export default TBD;
